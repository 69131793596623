<template>

    <Teleport to="#bottom-bar__right" :disabled="!isMobileHeader">
        
        <span v-if="contact">
            <span v-if="contact" class="">
                <a  :href="url" class="profile-button d-flex d-none d-xl-flex align-items-start">
                    <img src="https://tba.mints.cloud/public-assets/user" class="me-2" style="max-width: 19px;" alt="">
                    {{ contact.full_name }}
                </a>
            </span>
            <Teleport to="#header-login-app-end" :disabled="!tp">
                <a class="btn btn-initials text-white p-0 mb-0 d-flex align-items-center" :href="url" style="gap: 7px;line-height: 12px;">
                    {{ getInitials(contact.full_name)  }}
                </a>
            </Teleport>
        </span>
        <div v-else>
            <Teleport to="#header-login-app-end" :disabled="tp || !tp">
                <button class="btn text-white p-0 mb-0 d-flex align-items-center" style="gap: 7px;line-height: 12px;" type="button" @click="openLoginModal">
                    <img src="https://tba.mints.cloud/public-assets/user" class="" style="max-width: 19px;" alt="">
                    <span class="mt-1 d-none d-xl-flex">{{ title }}</span>
                </button>
            </Teleport>
            <LoginModal ref="loginModal"/>
        </div>

    </Teleport>

</template>

<script>
import LoginModal from './LoginModal.vue';

export default {
    inject: ['locale'],
    components: { LoginModal },
    props:{
      contact: {default: null, type: Object}
    },
	data() {
        return	{
            tp: true,
            isMobileHeader: false,
		}
    },
    computed: {
        title() {
            return this.locale === 'es' ? 'Ingresa a tu cuenta' : 'Login'
        },
        url(){
            return this.locale === 'es' ? '/es/perfil' : '/en/profile'
        }
    },
    mounted(){

        document.addEventListener("DOMContentLoaded", this.handleMobileHeader);
        
        window.addEventListener("resize", () => {
            if (window.innerWidth <= 1199) {
                this.isMobileHeader = true;

            } else {
                this.isMobileHeader = false;
            }
        });
    },
    methods: {
        openLoginModal() { this.$refs.loginModal.open(); },
        getInitials(full_name){
            let words = full_name.split(" ");
            let initials = words.map(word => word.charAt(0).toUpperCase());
            return initials.join("");
        },
        handleMobileHeader(){
            if (window.innerWidth <= 1199){
                this.isMobileHeader = true;
            } else {
                this.isMobileHeader = false;
            }
        }
    },
	created() {
		let windowWidth = window.innerWidth;
        this.tp = (windowWidth >= 1199);
		  window.addEventListener('resize', () => {
			windowWidth = window.innerWidth;
			  this.tp = (windowWidth >= 1199);
		  });
	},
}
</script>

<style lang="scss">
.profile-button{
    text-decoration: underline;
    &:hover{
        color: rgb(207, 207, 207);
    }
}
</style>