
import App from '@components/header-login/App.vue';
import CreateQuoteButton from '@components/header-login/CreateQuoteButton.vue';
import { generateAxiosInstance } from '@helpers/axios_provider.js';
import { generateApplication } from '@helpers/generate_component.js';

const axios = generateAxiosInstance();

import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"

generateApplication(App, "#header-login-app", {
    injections: [{
        name: 'locale',
        module: typeof locale != "undefined" ? locale : 'es'
    }, {
        name: '$axios',
        module: axios
    }],
    props: {
        contact: contact ? contact : null
    },
    packages: [autoAnimatePlugin, VueSweetalert2]
})


const createQuoteContainer = document.getElementById('request_proposal')
generateApplication(CreateQuoteButton, "#request_proposal", {
    injections: [{
        name: 'locale',
        module: typeof locale != "undefined" ? locale : 'es'
    }, {
        name: '$axios',
        module: axios
    }],
    props: {
        contact: contact ? contact : null,
        variables: {
            title: createQuoteContainer.attributes['data-title'].value,
            redirect_url: createQuoteContainer.attributes['data-redirect_url'].value,
        }
    },
    packages: [autoAnimatePlugin, VueSweetalert2]
})

