<template>
    <CaliModal id="LoginModal" ref="ModalLogin" v-if="isOpen" class="login-modal" modalSize="md" :closeModalImage="{src: closeImage}" @close="close">
    <template v-slot:header>
      <h4 class="modal-title" id="loginModalLabel"> </h4>
    </template>

    <template v-slot:body>
        <div class="modal-body">
            <h4 class="fw-bolder text-dark px-3 mb-4">{{ title }}</h4>
            <p class="description px-3"> {{ description }} </p>
            <div class="cali__login-card_body">
                <AuthForm ref="authForm" class="home__login-form" :options="options" @onSubmit="onSubmit" @onError="onError" @changeMode="changeMode" />
            </div>
        </div>
    </template>

    <template v-slot:footer><div></div></template>
  </CaliModal>
</template>
<script nonce>
import AuthForm from "@mints-components/cali-login/AuthForm.vue";
import useVuelidate from "@vuelidate/core";
import CaliModal from "@mints-components/cali-modal/App.vue";

export default {
    components: { AuthForm, CaliModal },
    inject: ['locale', '$axios'],

    setup() {
        return { v$: useVuelidate() };
    },

    data: () => ({
        passwordMode: false,
        options: {
            magicLink: { enabled: true, url: '/api/v1/login/send-magic-link' },
            password: { enabled: true, url: '/api/v1/login/password' },
            headers: { 'x-api-key': '9c6334a7-8215-46ff-8ec9-f442cdc43cac' }
        },
        isOpen: false,
        closeImage: 'https://tba.mints.cloud/public-assets/close-black',
    }),

    computed: {
        title() {
            return this.locale === 'es' ? 'Ingresa a tu cuenta' : 'Log in'
        },
        buttonText() {
            return this.locale === 'es' ? 'Continuar' : 'Submit';
        },
        description() {
            if (this.passwordMode) return this.byPassword
            return this.byMagickLink
        },
        byMagickLink() {
            return this.locale === 'es' ? 'Ingresa tu correo electrónico para recibir tu enlace de acceso' : 'Write your email and we will send you a Magic Link to your email for your login'
        },
        byPassword() {
            return this.locale === 'es' ? 'Inicia sesión con tu correo y contraseña' : 'Log in by password and email'
        },
    },

    methods: {
        open() { 
            this.isOpen = true;
        },
        close() { 
            this.isOpen = false; 
        },
        onSubmit(response) {
            let title = `Correo enviado `
            let text = `Si el correo es correcto, en un instante te llegará un correo de acceso a ${response?.credentials?.email}`

            if (response.type === 'password') {
                title = 'Autenticación correcta'
                text = 'Iniciando sesión...'
            }

            this.$swal.fire({
                icon: 'success',
                title: title,
                text: text
            })

            if (response.type === 'password') {
                setTimeout(() => {
                    window.location.href = '/'
                }, 1250)
            }
        },
        onError({ error, type, credentials }) {
            let title = `Correo enviado `
            let text = `Si el correo es correcto, en un instante te llegará un correo de acceso a ${credentials?.email}`
            let icon = 'success'

            if (type === 'password') {
              title = 'Oops...'
              text = 'Credenciales inválidas.'
              icon = 'error'
            }

            this.$swal.fire({
                icon: icon,
                title: title,
                text: text
            })
        },
        changeMode(mode) {
            this.passwordMode = mode
        }
    },
    validations() {
        return this.computedValidations;
    }
}
</script>

<style lang="scss">
.login-modal {
    .cali__modal-header {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        background: white !important;
        height: 0% !important;
    }
  .cali_modal-btn-close {
    img {
        padding-top: 0.4rem;
        height: 24px!important;
    }
    }
    .cali__modal-content {
        border: 1px solid rgba(0,0,0,0.2) !important;
        border-radius: .3rem !important;
    }
    .cali__modal-body{
        padding: 1rem 1rem 0rem 1rem !important;
    }
    .modal-body {
        padding: 0.5rem !important;
    }
    .cali__modal-dialog {
        margin-top: 25vh !important;
    }
    .cali__modal-footer{
        padding: 0rem 0rem;
    }
}
.cali__modal-mask.login-modal {
        background-color: rgba(0, 0, 0, 0.8) !important
}

@import "@/mints-components/cali-login/styles.css";

#LoginModal {
    .fw-bolder {
        color: #212529;
    }
    .description {
        font-size: 1.2em;
        color: #8A8A8A;
        @media (max-width:576px) {
            font-size: 0.82em;
        }
    }

    .cali__login-card_body {
        padding-top: 0;
    }

    .cali__login-card_body input {
        padding: 7px 10px !important;
        width: 100%;
    }

    .home__login-header {
        border-bottom: none;
        padding-bottom: 0;
    }

    .home__login-footer {
        border-top: none;
    }

    .home__login-btn-close {
        width: 2rem;
        height: 2rem;
        background: none;

        span {
            font-size: 2.8em;
            color: #000000;
        }
    }

    .home__login-form {
        .cali__login-button {
            background-color: #004562;
            min-width: 200px;
            color: white;
            max-width: 12rem;
            margin-left: auto;
            border-radius: 2rem;
            background-image:none;
            @media (max-width:768px) {
                max-width: 80%;
                margin-left: 0;
            }
        }

        .cali__login-text-end {
            display: flex;
            justify-content: flex-end;

            a {
                margin-left: 2px;
            }
        }
    }
}</style>