<template>
  <a v-if="contact?.contact_status === 'Habilitado'"
     :href="`/${this.locale}${this.variables['redirect_url']}`"
     class="btn btn-quote btn-outline- rounded-pill mb-0 text-nowrap ms-4"
     >
    {{ variables['title'] }}
  </a>

  <a v-else-if="!contact"
      @click="openLoginModal"
     class="btn btn-quote rounded-pill fw-bold mb-0 text-nowrap ms-4"
     >
    {{ variables['title'] }}
  </a>
  <LoginModal ref="loginModal"/>
</template>
<script>
import LoginModal from './LoginModal.vue';

export default {
  inject: ['locale'],
  components: { LoginModal },
  props:{
    contact: {default: null, type: Object},
    variables: { required: true, type: Object},
    title: {type: String},
    redirect_url: {type: String}
  },
  methods: {
    openLoginModal() { this.$refs.loginModal.open(); }}
  }
</script>

<style lang="scss">
.btn-quote {
    color: #000 !important;
    background-color: #ff9d00 !important;
    border-color: #ff9d00 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
}

</style>